<template>
  <div class="preview-courseware-container full-screen">
    <div class="preview-courseware-main">
      <div class="preview-courseware-title global-color">
        {{ data.zh_title || data.courseware_title }}
      </div>
      <div class="preview-courseware-date">{{ data.publish_date }}</div>
      <img
        @click="handleLike"
        class="preview-courseware-like"
        :src="
          require(`@/assets/img/${
            countInfo?.is_collect ? 'liking' : 'like'
          }.png`)
        "
        alt=""
      />
      <div class="preview-courseware-player">
        <div class="global-position full-screen">
          <PPTPlayer
            :data="data.courseware"
            :cfg="{
              sliding: true,
              page: true,
              point: true,
            }"
          >
            <template v-slot="{ cItems, cIndex }"> </template>
          </PPTPlayer>
        </div>
      </div>
      <div class="preview-courseware-button-group global-center">
        <CustomButton
          class="preview-courseware-button"
          @click="lessonPreparation"
          >一键备课</CustomButton
        >
      </div>
    </div>
    <div class="preview-courseware-footer">
      <IconButton
        title="周刊首页"
        :src="require('@/assets/images/icon/home.png')"
        @click="goBack"
      ></IconButton>
    </div>
    <Popup
      :msg="config.msg"
      v-if="config.isShow"
      @doClose="doClose"
      class="preview-courseware-popup"
    >
      <div class="preview_mate_doctor">
        <item-scroll ref="ItemScrollDoctor">
          <div style="">
            <div
              class="preview_mate_doctor_item"
              v-for="(item, i) in config.doctorList"
              :key="i"
              @click="selectExpert(item)"
            >
              {{ item.name }}-{{ item.hospital }}
            </div>
          </div>
        </item-scroll>
      </div>
    </Popup>
  </div>
</template>
<script>
import PPTPlayer from "@/components/player/PPTPlayer";
import CustomButton from "@/components/unit/CustomButton";
import IconButton from "@/components/unit/ButtonForIcon";
import Popup from "../../unit/Popup.vue";
import ItemScroll from "@/components/unit/ItemScroll";
export default {
  name: "preview-courseware",
  components: {
    PPTPlayer,
    CustomButton,
    IconButton,
    Popup,
    ItemScroll,
  },
  props: {},
  data() {
    return {
      data: {},
      config: {
        doctorList: [],
        isShow: false,
        msg: {
          title: "请选择备课专家",
          align: "center",
          btngroup: { close: "取消" },
        },
      },
      countInfo: {},
    };
  },
  created() {
    this.initData();
    this.initCountInfo();
  },
  methods: {
    initData() {
      let url = this.$tools.getURL(this.$urls.literature.detail, {
        id: this.$route.query.id,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.data = res.data;
        })
        .catch(() => {});
    },
    goBack() {
      this.$router.push({
        path: "/articleList",
      });
    },
    // 一键备课   弹窗选专家
    lessonPreparation() {
      let id = this.$store.state.common.userId;
      if (!id) {
        //销售
        this.config.isShow = true;
        this.loadDoctorData();
      } else {
        //专家本人

        this.$tools.setStorage("coursewareData", {
          literature_id: this.data.id,
          meet_topics: this.data.courseware_title,
          courseware_info: this.data.courseware,
          courseware_url: this.data.ppt_url || this.data.literature_pdf_url,
        });
        this.config.isShow = false;
        this.$router.push({
          path: "/meetInfo",
          query: {
            user_id: id,
            form: "/doctorInfo",
          },
        });
      }
    },
    //加载销售绑定的专家
    async loadDoctorData() {
      let url = this.$tools.getURL(this.$urls.sale.home, {});
      await this.$axios
        .get(url)
        .then((res) => {
          this.config.doctorList = res.data.doctor_data;
          setTimeout(() => {
            this.$refs.ItemScrollDoctor.initScroll();
          }, 500);
        })
        .catch(() => {});
    },
    //选择专家
    async selectExpert(item) {
      this.$tools.setStorage("coursewareData", {
        literature_id: this.data.id,
        meet_topics: this.data.courseware_title,
        courseware_info: this.data.courseware,
        courseware_url: this.data.ppt_url || this.data.literature_pdf_url,
      });
      this.config.isShow = false;

      this.$router.push({
        path: "/meetInfo",
        query: {
          user_id: item.id,
          is_sale: this.$route.query.is_sale,
          form: "/adminInfo",
        },
      });
    },
    doClose() {
      this.config.isShow = false;
    },
    initCountInfo() {
      let url = this.$tools.getURL(this.$urls.literature.countInfo, {
        id: this.$route.query.id,
        type: this.$store.state.common.userInfo.id
          ? 0
          : this.$store.state.common.salesInfo.phone
          ? 1
          : 0,
      });
      this.$axios
        .get(url)
        .then((res) => {
          this.countInfo = res.data;
        })
        .catch(() => {});
    },
    handleLike() {
      console.log(
        "    this.$store.state.common.userInfo.id || :>> ",
        this.$store.state.common,
        this.$store.state.common.salesInfo.phone
      );
      if (
        this.$store.state.common.userInfo.id ||
        this.$store.state.common.salesInfo.phone
      ) {
        let url = this.$tools.getURL(this.$urls.literature.collect, {
          id: this.$route.query.id,
          type: this.$store.state.common.userInfo.id
            ? 0
            : this.$store.state.common.salesInfo.phone
            ? 1
            : 0,
        });
        this.$axios
          .get(url)
          .then((res) => {
            console.log("res :>> ", res);
            this.$tips.success({ text: res.msg });
            this.countInfo.is_collect = this.countInfo.is_collect ? 0 : 1;
          })
          .catch(() => {});
      } else {
        this.$router.push({
          path: "/doctorLogin",
          query: {
            form: this.$route.path,
            id: this.$route.query.id,
          },
        });
      }
    },
  },
};
</script>
<style>
.preview-courseware-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px 0 20px;
  /* align-items: center; */
}

.preview-courseware-title {
  padding: 0 40px;
  font-size: 14px;
  font-weight: bold;
  text-align: center;
}
.preview-courseware-date {
  font-weight: 400;
  font-size: 13px;
  color: #606060;
  text-align: center;
  padding: 10px 0;
}

.preview-courseware-player {
  width: 100%;
  padding-bottom: 100%;
  /* 高度和宽度相等 */
  position: relative;
}

.preview-courseware-content {
}
.preview-courseware-button-group {
  padding: 20px 0;
}
.preview-courseware-button {
  width: 90px !important;
  font-size: 14px;
  padding: 5px;
}
.preview_mate_doctor {
  margin-top: 8px;
  width: 100%;
  height: 160px;
}
.preview_mate_doctor_item {
  width: 300px;
  padding-left: 30px;
  line-height: 2.5;
  border-bottom: 1px solid #ccc;
}
.preview_mate_doctor :last-child {
  border-bottom: none !important;
}
.preview-courseware-popup .customButton_container {
  width: 90px !important;
  font-size: 14px;
  padding: 12px;
}
.preview-courseware-main {
  position: relative;
}
.preview-courseware-like {
  width: 25px;
  position: absolute;
  right: 9px;
  top: -4px;
}
</style>